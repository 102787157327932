import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import weatherApp from '../assets/images/weatherApp.gif'
import weather from '../assets/images/weather.jpg'

const Weather = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="Project - Weather forecast" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Weather Forecast</h1>
          </header>
          <p>
            A weather application that sets a current condition, and hourly
            temperatures throughout the day, and 7 days forecasts.
            <br />
            This application was the perfect way to improve my understanding of
            making queries and managing larger sets of data. In this project, I
            utilized Unsplash API to get a picture ,OpenWeatherMap API to gather
            the weather data and injected this data based on the user's city
            name input.
          </p>
          <tbody>
            <tr>
              <th className="types">Type</th>
              <th className="types">Stack</th>
              <th className="types">Code</th>
              <th className="types">Live</th>
            </tr>
            <tr>
              <td>Personal</td>
              <td>React</td>
              <td class="ecommerce">
                <a
                  href="https://github.com/taekimura/Weather-forecast-hooks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Repository
                </a>
              </td>
              <td class="Decore__Bold-sc-1tbn2mo-3 gicxJS">
                <a
                  href="https://taekimura.github.io/weather-forecast/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Site
                </a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Chart.js</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Hooks</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>axios</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <span className="image main">
            <img src={weatherApp} alt="" />
          </span>
          <span className="image main">
            <img src={weather} alt="" />
          </span>
          <ul className="actions2">
            <li>
              <Link to="/#projects">Back</Link>
            </li>
          </ul>
          <br />
        </div>
      </section>
    </div>
  </Layout>
)

export default Weather
